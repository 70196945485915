import {
  PARTS_BOTTOM,
  PARTS_LEFT,
  PARTS_RIGHT,
  PARTS_TOP,
  PC_BASE_BLUEPRINT,
  PC_STATUS_BLUEPRINT,
  PC_ADD_BLUEPRINT,
  PC_VALID_DAYS,
  PHOTO_TYPE,
} from '../../constants/PerformanceCheckConstants';
import React, { forwardRef, useEffect, useState } from 'react';
import { myInfoSelector } from '../../recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';
// import { CERTIFICATE_DATAS } from '../../constants/InsuranceDataConstants';
import { CommonUtil } from '../../utils/commonUtil';
// import { EW_CERTIFICATE_DATAS } from '../../constants/EWDataConstants';
import { ServiceProvider } from '../../services';
// import { YN } from '../../constants/Constants';
import _ from 'lodash';
import dayjs from 'dayjs';

import Checked from '../../assets/icons/checked.webp';
import Unchecked from '../../assets/icons/unchecked.webp';
import BP from '../../assets/images/bp.webp';
import BPBottom from '../../assets/images/bp-bottom.webp';
import BPLeft from '../../assets/images/bp-left.webp';
import BPRight from '../../assets/images/bp-right.webp';
import BPTop from '../../assets/images/bp-top.webp';
import HyundaiHeader from '../../assets/images/insurance/GUIDE_0009_Header.png';
import HyundaiFooter from '../../assets/images/insurance/GUIDE_0009_Footer.png';
import IconStatus1 from '../../assets/images/icon-status1.svg';
import IconStatus2 from '../../assets/images/icon-status2.svg';
import IconStatus3 from '../../assets/images/icon-status3.svg';
import IconStatus4 from '../../assets/images/icon-status4.svg';
import IconStatus5 from '../../assets/images/icon-status5.svg';
import IconStatus6 from '../../assets/images/icon-status6.svg';
import { YN } from '../../constants/Constants';
import { EW_CERTIFICATE_DATAS } from '../../constants/EWDataConstants';
import { CERTIFICATE_DATAS } from '../../constants/InsuranceDataConstants';
import LogoWhite from '../../assets/icons/logo-handok.webp';
import { Image } from 'primereact/image';
import PerformanceCheckReportHeader from './PerformanceCheckReportHeader';

const baseTitles = _.mapValues(PC_BASE_BLUEPRINT, 'title');
const baseValueItems = _.mapValues(PC_BASE_BLUEPRINT, 'valueItems');
const statusTitles = _.mapValues(PC_STATUS_BLUEPRINT, 'title');
const statusValueItems = _.mapValues(PC_STATUS_BLUEPRINT, 'valueItems');
const addTitles = _.mapValues(PC_ADD_BLUEPRINT, 'title');
const addValueItems = _.mapValues(PC_ADD_BLUEPRINT, 'valueItems');

const associationService = ServiceProvider.association;
const userService = ServiceProvider.user;
const performanceCheckService = ServiceProvider.performanceCheck;

const setCheckState = (raw, key, compare) => {
  if (_.isArray(compare))
    return _.includes(compare, _.get(raw, key)) ? Checked : Unchecked;
  return _.get(raw, key) === compare ? Checked : Unchecked;
};

const setSubCheckState = (raw, keys, compares) => {
  let count = 0;
  for (const key of keys) {
    if (_.includes(compares, _.get(raw, key))) count++;
  }
  return count === 0 ? Unchecked : Checked;
};

const setPartState = (value) => {
  let label = '';

  switch (value) {
    case '1':
      return '';
    case '2':
      return 'X';
    case '3':
      return 'W';
    case '4':
      return 'C';
    case '5':
      return 'A';
    case '6':
      return 'U';
    case '7':
      return 'T';
    default:
      break;
  }

  return label;
};

const PerformanceCheckReport = forwardRef(
  (
    {
      data,
      addData,
      photos,
      payment,
      association,
      inspector,
      customerCompany,
      vehicleInformation,
    },
    ref
  ) => {
    const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
    const insurancePolicyNumber =
      myInfoLoadable.contents.shopInfo?.insurancePolicyNumber;
    const [representative, setRepresantative] = useState('');
    const [officialSealUrls, setOfficialSealUrls] = useState({});
    const [isAvailable, setIsAvailable] = useState({
      certificate: false,
      ew: false,
    });
    const [totalPage, setTotalPage] = useState('');
    const [imagePages, setImagePages] = useState([]);
    const totalValues = {
      checkData: data,
      addData: addData,
      photos,
      paymentInformation: payment,
      association,
      inspector,
      vehicleInformation,
      insurancePolicyNumber,
    };

    async function getEncodedImgUrl(imageUrl) {
      return process.env.REACT_APP_S3_BASE_URL + imageUrl;
    }

    async function getAssociationSealUrl(association) {
      const { associationId } = association;

      try {
        const response = await associationService.getDataMobile(associationId);
        const { officialSealURL } = response.data;

        if (officialSealURL) {
          const encodedUrl = await getEncodedImgUrl(officialSealURL);
          setOfficialSealUrls((ps) => ({ ...ps, association: encodedUrl }));
        }

        // 대표자명
        const { representative } = response.data;
        setRepresantative(representative);
      } catch (error) {
        console.error('Failed to load association official seal:', error);
      }
    }

    async function getUserSealUrl(inspector) {
      const { userId } = inspector;

      try {
        const response = await userService.getDataExternal(userId);
        const { officialSealURL } = response.data;

        if (officialSealURL) {
          const encodedUrl = await getEncodedImgUrl(officialSealURL);
          setOfficialSealUrls((ps) => ({ ...ps, inspector: encodedUrl }));
        }
      } catch (error) {
        console.error('Failed to load inspector official seal:', error);
      }
    }

    function importCertImage(insuranceCode) {
      try {
        return [
          {
            front: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_01.jpg`),
            rear: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_02.jpg`),
          },
          true,
        ];
      } catch (error) {
        console.log(error);
        return [
          {
            front: null,
            rear: null,
          },
          false,
        ];
      }
    }

    function renderInsuranceCertificate(insuranceCode) {
      // [09] 현대해상은 이미지가 아닌 직접 만든 보험 양식으로 대체하므로 바로 반환
      if (insuranceCode === '09') return;
      const [urls] = importCertImage(insuranceCode);
      const { front, rear } = urls;

      const datas = _.filter(CERTIFICATE_DATAS, (data) => {
        return _.get(data, 'insuranceCompanyCode').indexOf(insuranceCode) >= 0;
      });

      // const datas = _.filter(CERTIFICATE_DATAS, (data) => {
      //   return _.get(data, 'insuranceCompanyCode').indexOf('10') >= 0;
      // });

      return (
        <div className="paper_container">
          <div
            className="paper"
            key={`insurance_certificate_page_${insuranceCode}_front`}
          >
            <table className="w-full h-full">
              <thead />
              <tbody>
                <tr className="h-1rem">
                  <td className="text-xs" />
                  <td className="text-xs text-right">{`(5 / ${totalPage}쪽)`}</td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="content"
                    style={{ position: 'relative' }}
                  >
                    <img
                      src={front}
                      alt="insurance_certificate_front"
                      style={{
                        width: '100%',
                        height: 'calc(100% -4px)',
                      }}
                    />
                    {datas.map((data) => {
                      const [top, left] = _.get(data.pos, insuranceCode);
                      // const [top, left] = _.get(data.pos, '10');
                      return (
                        <span
                          key={data.objName + data.name}
                          className="font-bold"
                          style={{
                            position: 'absolute',
                            top: top,
                            left: left,
                            color: data.color || 'black',
                          }}
                        >
                          {/* {_.get(
                              totalValues,
                              `${data.objName}.${data.fieldName}`
                            )} */}
                          {data.getValue(totalValues)}
                        </span>
                      );
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="paper"
            key={`insurance_certificate_page_${insuranceCode}_rear`}
          >
            <table className="w-full h-full">
              <thead />
              <tbody>
                <tr className="h-1rem">
                  <td className="text-xs" />
                  <td className="text-xs text-right">{`(6 / ${totalPage}쪽)`}</td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="content"
                    style={{ position: 'relative' }}
                  >
                    <img
                      src={rear}
                      alt="insurance_certificate_rear"
                      style={{
                        width: '100%',
                        height: 'calc(100% -4px)',
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    function importEwImage(insuranceCode) {
      const isEw = _.get(totalValues, 'paymentInformation.ewYN') === YN.YES;
      try {
        return [
          {
            front: require(`../../assets/images/ew/EW_00${insuranceCode}_01.png`),
            rear: require(`../../assets/images/ew/EW_00${insuranceCode}_02.png`),
          },
          true && isEw,
        ];
      } catch (error) {
        console.log(error);
        return [
          {
            front: null,
            rear: null,
          },
          false,
        ];
      }
    }

    function renderEwInsuranceCertificate(insuranceCode) {
      // const isEw = _.get(totalValues, 'paymentInformation.ewYN') === YN.YES;

      // [09] 현대해상은 이미지가 아닌 직접 만든 보험 양식으로 대체하므로 바로 반환
      if (insuranceCode === '09') return;
      const { ewYN } = payment;
      console.log(ewYN);
      const [urls] = ewYN ? null : importEwImage(insuranceCode);
      const { front, rear } = urls;

      const datas = _.filter(EW_CERTIFICATE_DATAS, (data) => {
        return _.get(data, 'insuranceCompanyCode').indexOf(insuranceCode) >= 0;
      });

      return (
        <div className="paper_container">
          <div
            className="paper"
            key={`ew_insurance_certificate_page_${insuranceCode}_front`}
            // style={{
            //   width: '100%',
            //   height: '100%',
            // }}
          >
            <table className="w-full h-full">
              <thead />
              <tbody>
                <tr className="h-1rem">
                  <td className="text-xs" />
                  <td className="text-xs text-right">{`(7 / ${totalPage}쪽)`}</td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="content"
                    style={{
                      position: 'relative',
                      // width: '100%',
                      // height: '100%',
                    }}
                  >
                    <img
                      src={front}
                      alt="ew_insurance_certificate_front"
                      style={{
                        width: '100%',
                        height: 'calc(100% -4px)',
                      }}
                    />
                    {datas.map((data) => {
                      const [top, left] = _.get(data.pos, insuranceCode);
                      return (
                        <span
                          key={data.objName + data.name}
                          className={`${data.styleClass} font-semibold`}
                          style={{
                            position: 'absolute',
                            top: top,
                            left: left,
                            color: data.color || 'black',
                          }}
                        >
                          {data.getValue(totalValues)}
                        </span>
                      );
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="paper"
            key={`ew_insurance_certificate_page_${insuranceCode}_rear`}
          >
            <table className="w-full h-full">
              <thead />
              <tbody>
                <tr className="h-1rem">
                  <td className="text-xs" />
                  <td className="text-xs text-right">{`(8 / ${totalPage}쪽)`}</td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className="content"
                    style={{ position: 'relative' }}
                  >
                    <img
                      src={rear}
                      alt="ew_insurance_certificate_front"
                      style={{
                        width: '100%',
                        height: 'calc(100% -4px)',
                      }}
                    />
                    {datas
                      .filter((data) => data.fieldName === 'enterpriseName')
                      .map((data) => {
                        const [top, left] = _.get(data.pos, insuranceCode);
                        return (
                          <span
                            key={data.objName + data.name}
                            className={`${data.styleClass} font-semibold`}
                            style={{
                              position: 'absolute',
                              top: top,
                              left: left,
                              color: data.color || 'black',
                            }}
                          >
                            {data.getValue(totalValues)}
                          </span>
                        );
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    const CheckboxGroup = ({ options, data, setCheckState, itemKey }) => {
      return (
        <div className="flex flex-row align-items-center justify-content-start gap-3">
          {options &&
            options.map((option, index) => (
              <div
                key={`chk_${itemKey}_${index}`}
                className="flex flex-row align-items-center justify-content-start gap-1"
              >
                <img
                  className="cbi"
                  alt={`checkbox for ${option.label}`}
                  src={setCheckState(data, itemKey, option.value)}
                />
                {option.label}
              </div>
            ))}
        </div>
      );
    };

    const TableRow = ({
      className = '',
      colSpan = 2,
      title,
      itemKey,
      options,
      data,
      setCheckState,
    }) => (
      <>
        <td className={className} colSpan={colSpan}>
          {title}
        </td>
        <td>
          <CheckboxGroup
            itemKey={itemKey}
            options={options}
            data={data}
            setCheckState={setCheckState}
          />
        </td>
      </>
    );

    const formatDate = (date) => {
      if (!date) return '년     월     일';
      return dayjs(date).format('YYYY년 MM월 DD일');
    };

    const getEndDate = (startDate) => {
      if (!startDate) return '년     월     일';
      return dayjs(startDate)
        .add(PC_VALID_DAYS, 'day')
        .format('YYYY년 MM월 DD일');
      // return dayjs(startDate).add(2, 'month').format('YYYY 년 MM 월 DD 일');
    };

    function calculateImagePages(images) {
      // 페이지 당 이미지 수
      const IMAGES_PER_PAGE = 4;
      // 페이지 수 계산
      const totalPages = Math.ceil(images.length / IMAGES_PER_PAGE);
      // 이미지를 페이지 단위로 나눔
      const newimagePages = [];
      for (let i = 0; i < totalPages; i++) {
        newimagePages.push(
          images.slice(i * IMAGES_PER_PAGE, (i + 1) * IMAGES_PER_PAGE)
        );
      }

      return newimagePages;
    }

    useEffect(() => {
      (async () => {
        if (association !== null) {
          await getAssociationSealUrl(association);
        }

        if (inspector !== null) {
          await getUserSealUrl(inspector);
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [association, inspector]);

    useEffect(() => {
      if (payment) {
        const { ICNY_CODE, ewYN } = payment;
        const [certificateUrl, isCertificateAvailable] =
          importCertImage(ICNY_CODE);
        const isEw = ewYN === 'Y';
        const [ewUrl, isEwAvailable] = isEw ? null : importEwImage(ICNY_CODE);

        setIsAvailable({
          certificate: isCertificateAvailable,
          ew: isEwAvailable,
        });

        let totalPage = 4;

        if (isCertificateAvailable) {
          totalPage += 2;
        }

        if (isEwAvailable) {
          totalPage += 2;
        }

        setTotalPage(String(totalPage));
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment]);

    useEffect(() => {
      (async () => {
        if (photos && photos.length > 0) {
          // 한독은 모든 이미지 보이게 한다고 함
          async function getFormattedImages(images) {
            return await Promise.all(
              images
                .sort((a, b) => {
                  // 정면, 후면을 앞으로 배치
                  const partOrder = ['OUT_FRONT', 'OUT_REAR'];
                  const aIndex = partOrder.indexOf(a.partCode);
                  const bIndex = partOrder.indexOf(b.partCode);
                  if (aIndex === -1 && bIndex === -1) return 0; // 둘 다 순위에 없으면 그대로
                  if (aIndex === -1) return 1; // a가 순위에 없으면 b를 뒤로
                  if (bIndex === -1) return -1; // b가 순위에 없으면 a를 앞으로
                  return aIndex - bIndex; // 순서대로 배치
                })
                .map(async (img) => {
                  const encodedUrl = await getEncodedImgUrl(
                    _.get(img, 'filePath')
                  );
                  const alt = _.get(img, 'partCode');

                  img.src = encodedUrl;
                  img.alt = alt || '기타';

                  return img;
                })
            );
          }
          const formattedImages = await getFormattedImages(photos);
          setImagePages(calculateImagePages(formattedImages));
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos]);

    return (
      <div ref={ref} id="performance-check-report" className="pcr">
        {/* 표지 */}
        <div className="paper_container">
          <div className="paper bg-black-alpha-90 p-5 relative overflow-hidden text-white flex flex-column justify-content-between">
            <div>
              <header className="w-full flex justify-content-between align-contents-center px-3 pt-5 pb-8 mb-8">
                <div className="flex gap-2">
                  {/* <Image className="" src={LogoWhite} alt="logo" width="30" /> */}
                  <p className="text-3xl font-semibold">HANDOK</p>
                </div>
                <p className="text-2xl">한독자동차㈜</p>
              </header>
              <div className="w-full flex justify-content-between align-items-end px-3 py-5 border-top-1 border-bottom-1 border-white">
                <h1 className="text-6xl mb-0 line-height-2">
                  HD 프리미엄 중고차
                  <br />
                  성능 상태 기록부
                </h1>
                <p className="text-xl">
                  점검일 <strong>{formatDate(data?.CHCK_DE)}</strong>
                </p>
              </div>
            </div>
            <div className="py-3">
              <p className="text-xl font-bold ">
                개인간 직거래의 활성화를 위해 만들어진 프리미엄 중고차 성능
                기록부입니다.
              </p>
            </div>
            <Image
              className="absolute opacity-20"
              style={{ bottom: '-130px', right: '-170px' }}
              src={LogoWhite}
              alt="logo"
              width="700"
            />
          </div>
        </div>
        {/* 1페이지 (NEW) */}
        <div className="paper_container">
          <div className="paper">
            {/* @고정 헤더 */}
            <div className="watermark"></div>
            <PerformanceCheckReportHeader />
            {/* @차량의 기본정보 */}
            <div className="section_title_new">
              <h4>차량의 기본정보</h4>
              {/* <img src={IconCar1} alt="차량의 기본정보" className="mr-3" /> */}
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="title">차명</td>
                  <td>{_.get(data, 'CNM')}</td>
                  <td className="title">차량번호</td>
                  <td>{_.get(data, 'VHRNO')}</td>
                </tr>
                <tr>
                  <td className="title">연식</td>
                  <td>{_.get(data, 'PRYE')}</td>
                  <td className="title">검사 유효기간</td>
                  <td>
                    {dayjs(_.get(data, 'INSPT_VALID_PD_BGNDE')).format(
                      'YYYY년 MM월 DD일'
                    )}{' '}
                    ~{' '}
                    {dayjs(_.get(data, 'INSPT_VALID_PD_ENDDE')).format(
                      'YYYY년 MM월 DD일'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="title">최초등록일</td>
                  <td>
                    {dayjs(_.get(data, 'FRST_REGIST_DE')).format(
                      'YYYY년 MM월 DD일'
                    )}
                  </td>
                  <td rowSpan="2" className="title">
                    변속기종류
                  </td>
                  <td rowSpan="2">
                    <div className="flex flex-column">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'A')}
                          />
                          자동
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'M')}
                          />
                          수동
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'S')}
                          />
                          세미오토
                        </div>
                      </div>
                      <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'C')}
                          />
                          무단변속기
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'X')}
                          />
                          기타 (
                          {_.get(data, 'GRBX_KND_DTLS') ||
                            '\u00A0\u00A0\u00A0\u00A0'}
                          )
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="title">차대번호</td>
                  <td>
                    {_.get(data, 'VIN').length === 11
                      ? `${_.get(data, 'VIN')}${_.get(
                          vehicleInformation,
                          'vinRemain'
                        )}`
                      : `${_.get(data, 'VIN')}`}
                  </td>
                </tr>
                <tr>
                  <td className="title">사용연료</td>
                  <td colSpan="3">
                    <CheckboxGroup
                      itemKey="USE_FUEL_CODE"
                      options={baseValueItems['USE_FUEL_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">보증유형</td>
                  <td
                    colSpan="3"
                    style={{
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'ASSRNC_TY_SE_CODE', '2')}
                        />
                        보험사보증 (성능보증이 가능한 차량)
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'ASSRNC_TY_SE_CODE', '1')}
                        />
                        보험사비보증 (13만Km 이상)
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="title">원동기형식</td>
                  <td colSpan="3">{_.get(data, 'MTRS_FOM')}</td>
                </tr>
              </tbody>
            </table>
            {/* @자동차 상세정보 */}
            <div className="section_title_new mt-6 ">
              <h4>자동차 상세 정보</h4>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="32%" />
                <col width="18%" />
                <col width="32%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="title">사용이력</td>
                  <td className="title text-center">상태</td>
                  <td className="title text-center" colSpan="2">
                    항목
                  </td>
                </tr>
                <tr>
                  <td className="title">
                    주행거리 상태
                    <br />
                    (1년 2만Km 기준)
                  </td>
                  <td>
                    <CheckboxGroup
                      itemKey="TRVL_DSTNC_STTUS_CODE"
                      options={baseValueItems['TRVL_DSTNC_STTUS_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                  <td className="title">
                    현재 주행거리
                    <br />
                    (검사시 계시판 기준)
                  </td>
                  <td>
                    <span className="font-bold">
                      {CommonUtil.Unit.format(_.get(data, 'TRVL_DSTNC'))}
                    </span>
                    &nbsp; KM
                  </td>
                </tr>
                <tr>
                  <td className="title">계기 상태</td>
                  <td colSpan="3">
                    <CheckboxGroup
                      itemKey="GAUGE_FNCT_AT"
                      options={baseValueItems['GAUGE_FNCT_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">차대번호 표기</td>
                  <td colSpan="3">
                    <CheckboxGroup
                      itemKey="SAMENSS_CNFIRM_CODE"
                      options={baseValueItems['SAMENSS_CNFIRM_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">배출가스</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(
                            data,
                            'CHK_CRMN_MESURE_VALUE',
                            'Y'
                          )}
                        />
                        일산화탄소
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(
                            data,
                            'CHK_HYDR_MESURE_VALUE',
                            'Y'
                          )}
                        />
                        탄화수소
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(
                            data,
                            'CHK_SMOKE_MESURE_VALUE',
                            'Y'
                          )}
                        />
                        매연
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <span>
                        {_.get(data, 'CRMN_MESURE_VALUE') === ''
                          ? '0.0'
                          : _.get(data, 'CRMN_MESURE_VALUE')}{' '}
                        %,
                      </span>
                      <span>
                        {_.get(data, 'HYDR_MESURE_VALUE') === ''
                          ? '0'
                          : _.get(data, 'HYDR_MESURE_VALUE')}{' '}
                        ppm,
                      </span>
                      <span>
                        {_.get(data, 'SMOKE_MESURE_VALUE') === ''
                          ? '0'
                          : _.get(data, 'SMOKE_MESURE_VALUE')}{' '}
                        %
                      </span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="title">튜닝</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'UNLAW_STMD_AT', '0')}
                        />
                        없음
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'UNLAW_STMD_AT', ['1', '2'])}
                        />
                        있음
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <CheckboxGroup
                      itemKey="UNLAW_STMD_AT"
                      options={baseValueItems['UNLAW_STMD_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">특별이력</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'FLUD_AT', '0')}
                        />
                        없음
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'FLUD_AT', ['1', '2'])}
                        />
                        있음
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <CheckboxGroup
                      itemKey="FLUD_AT"
                      options={baseValueItems['FLUD_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="title">용도변경</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'PRPOS_CHANGE_SE_CODE', '0')}
                        />
                        없음
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'PRPOS_CHANGE_SE_CODE', [
                            '1',
                            '2',
                            '3',
                          ])}
                        />
                        있음
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <CheckboxGroup
                      itemKey="PRPOS_CHANGE_SE_CODE"
                      options={baseValueItems['PRPOS_CHANGE_SE_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                {/* TODO 차량 색상, 주요옵션 */}
                {/* <tr>
                    <td className="title">색상</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_AT', '1')}
                          />
                          무채색
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_AT', '2')}
                          />
                          유채색
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_CHANGE_AT', '1')}
                          />
                          전체도색
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_CHANGE_AT', '2')}
                          />
                          색상변경
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">주요옵션</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'TODO', '1')}
                          />
                          없음
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'TODO', '1')}
                          />
                          있음
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'MAIN_OPTN_VHCLE_RF_AT',
                              '1'
                            )}
                          />
                          썬루프
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'MAIN_OPTN_ROAD_GUIDANCE_AT',
                              '1'
                            )}
                          />
                          네비게이션
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'MAIN_OPTN_ETC_AT', '1')}
                          />
                          기타
                        </div>
                      </div>
                    </td>
                  </tr> */}
                <tr>
                  <td className="title">리콜대상</td>
                  <td>
                    <CheckboxGroup
                      itemKey="RECALL_TRGET_AT"
                      options={baseValueItems['RECALL_TRGET_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                  <td className="title">리콜이행</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={
                            _.get(data, 'RECALL_TRGET_AT') === '1'
                              ? setCheckState(data, 'RECALL_FLFL_AT', '1')
                              : Unchecked
                          }
                        />
                        이행
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={
                            _.get(data, 'RECALL_TRGET_AT') === '1'
                              ? setCheckState(data, 'RECALL_FLFL_AT', '0')
                              : Unchecked
                          }
                        />
                        미이행
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 2페이지 */}
        <div className="paper_container">
          <div className="paper">
            {/* @고정 헤더 */}
            <div className="watermark"></div>
            <PerformanceCheckReportHeader />
            {/* @ 차량 사고 교환 수리 이력 */}
            <div className="section_title_new mt-6">
              <h4>차량 사고 교환 수리 이력</h4>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="32%" />
                <col width="18%" />
                <col width="32%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="bg-gray-100" colSpan="4">
                    <div className="flex flex-row align-items-center justify-content-start">
                      <div className="mr-2">※ 상태표시 부호 :</div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus1} alt="교환" />
                          교환
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus2} alt="판금 또는 용접" />
                          판금 또는 용접
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus3} alt="부식" />
                          부식
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus4} alt="흠집" />
                          흠집
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus5} alt="요철" />
                          요철
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus6} alt="손상" />
                          손상
                        </div>
                      </div>
                    </div>
                    ※ 하단 항목은 승용차 기준이며, 기타 자동차는 승용차에 준하여
                    표시
                  </td>
                </tr>

                <tr>
                  <td colSpan="11" className=" p-0">
                    <div className="flex flex-row align-items-center justify-content-evenly relative">
                      <div className="relative">
                        <img
                          src={BPLeft}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_LEFT,
                          ({ partPoint, rpos: [top, left] }) => (
                            <div
                              key={`key_${partPoint}`}
                              className="cerp-pp absolute text-center text-lg font-bold"
                              style={{ top, left }}
                            >
                              {setPartState(_.get(data, partPoint))}
                            </div>
                          )
                        )}
                      </div>
                      <div className="relative">
                        <img
                          src={BPTop}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_TOP,
                          ({ partPoint, rpos: [top, left] }) => (
                            <div
                              key={`key_${partPoint}`}
                              // className="cerp-pp absolute text-center text-base font-bold"
                              className="cerp-pp absolute text-center text-xl font-bold"
                              style={{ top, left }}
                            >
                              {setPartState(_.get(data, partPoint))}
                            </div>
                          )
                        )}
                      </div>
                      <div className="relative">
                        <img
                          src={BPBottom}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          // className="m-0 p-0"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_BOTTOM,
                          ({ partPoint, rpos: [top, left] }) => (
                            <div
                              key={`key_${partPoint}`}
                              // className="cerp-pp absolute text-center text-base font-bold"
                              className="cerp-pp absolute text-center text-xl font-bold"
                              style={{ top, left }}
                            >
                              {setPartState(_.get(data, partPoint))}
                            </div>
                          )
                        )}
                      </div>
                      <div className="relative">
                        <img
                          src={BPRight}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_RIGHT,
                          ({ partPoint, rpos: [top, left] }) => (
                            <div
                              key={`key_${partPoint}`}
                              // className="cerp-pp absolute text-center text-base font-bold"
                              className="cerp-pp absolute text-center text-xl font-bold"
                              style={{ top, left }}
                            >
                              {setPartState(_.get(data, partPoint))}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="title">
                    사고이력
                    <p className="text-xs">
                      (차량의 중요부위 사고가 있는 경우)
                    </p>
                  </td>
                  <td>
                    <CheckboxGroup
                      itemKey="ACDNT_AT"
                      options={baseValueItems['ACDNT_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                  <td className="title">
                    단순수리
                    <p className="text-xs">
                      (차량의 단순뷔위 교환, 판금 있는 경우)
                    </p>
                  </td>
                  <td>
                    <CheckboxGroup
                      itemKey="SIMPL_REPAIR_AT"
                      options={baseValueItems['SIMPL_REPAIR_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="section_title_gray_new mt-6">
              <h5>교환, 판금 등 이상 부위 상세 (위 차량 부위 참조)</h5>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="20.5%" />
                <col width="61.5%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="title" rowSpan="2">
                    외판부위
                  </td>
                  <td className="text-center">1랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's037', ['2', '3', '4'])}
                        />
                        1. 후드
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's038', ['2', '3', '4'])}
                        />
                        2. 프론트휀더
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's039', ['2', '3', '4'])}
                        />
                        3. 도어
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's040', ['2', '3', '4'])}
                        />
                        4. 트렁크 리드
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's041', ['2', '3', '4'])}
                        />
                        5. 라디에이터서포트(볼트체결부품)
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">2랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's043', ['2', '3', '4'])}
                        />
                        6. 쿼터패널(리어휀더)
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's042', ['2', '3', '4'])}
                        />
                        7. 루프패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's044', ['2', '3', '4'])}
                        />
                        8. 사이드실패널
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td rowSpan="3" className="title">
                    주요골격
                  </td>
                  <td className="text-center">A랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's045', ['2', '3', '4'])}
                        />
                        9. 프론트패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's046', ['2', '3', '4'])}
                        />
                        10. 크로스멤버
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's047', ['2', '3', '4'])}
                        />
                        11. 인사이드패널
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's054', ['2', '3', '4'])}
                        />
                        17. 트렁크플로어
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's053', ['2', '3', '4'])}
                        />
                        18. 리어패널
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">B랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's048', ['2', '3', '4'])}
                        />
                        12. 사이드멤버
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's049', ['2', '3', '4'])}
                        />
                        13. 휠하우스
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's052', ['2', '3', '4'])}
                        />
                        14. 필러패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-2">
                        <div>(</div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setSubCheckState(
                              data,
                              ['pp_63', 'pp_78'],
                              ['2', '3', '4']
                            )}
                          />
                          A,
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setSubCheckState(
                              data,
                              ['pp_103', 'pp_118'],
                              ['2', '3', '4']
                            )}
                          />
                          B,
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setSubCheckState(
                              data,
                              ['pp_144', 'pp_137'],
                              ['2', '3', '4']
                            )}
                          />
                          C
                        </div>
                        <div>)</div>
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's056', ['2', '3', '4'])}
                        />
                        19. 패키지트레이
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">C랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's050', ['2', '3', '4'])}
                        />
                        15. 대쉬패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's051', ['2', '3', '4'])}
                        />
                        16. 플로어패널
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 3페이지 */}
        <div className="paper_container">
          <div className="paper">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader />
            <div className="section_title_gray_new mt-4">
              <h5>차량 주요장치 누유 및 불량 상세</h5>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="20.5%" />
                <col width="20.5%" />
                <col width="41%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="title">주요장치</td>
                  <td colSpan="2" className="title text-center">
                    항목 / 해당부품
                  </td>
                  <td className="title text-center">상태</td>
                </tr>
                <tr>
                  <td rowSpan="2" className="title">
                    자기진단
                  </td>
                  <TableRow
                    colSpan="2"
                    title={baseTitles['ENGINE_STBLT_AT']}
                    itemKey="ENGINE_STBLT_AT"
                    options={baseValueItems['ENGINE_STBLT_AT']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <TableRow
                    colSpan="2"
                    title={baseTitles['GRBX_STBLT_AT']}
                    itemKey="GRBX_STBLT_AT"
                    options={baseValueItems['GRBX_STBLT_AT']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td className="title" rowSpan="10">
                    원동기 (엔진)
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s001']}
                    itemKey="s001"
                    options={statusValueItems['s001']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="3">오일누유</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s057']}
                    itemKey="s057"
                    options={statusValueItems['s057']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s003', 's058'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s005']}
                    itemKey="s005"
                    options={statusValueItems['s005']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="4">냉각수 누수</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s007']}
                    itemKey="s007"
                    options={statusValueItems['s007']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s009', 's008', 's010'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s011']}
                    itemKey="s011"
                    options={statusValueItems['s011']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="7" className="title">
                    변속기 (미션)
                  </td>
                  <td rowSpan="3">자동변속기 (A/T)</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s012']}
                    itemKey="s012"
                    options={statusValueItems['s012']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s013', 's016'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="4">수동변속기 (M/T)</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s018']}
                    itemKey="s018"
                    options={statusValueItems['s018']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s017', 's019', 's020'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 4페이지 */}
        <div className="paper_container">
          <div className="paper">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader mini={true} />
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="20.5%" />
                <col width="20.5%" />
                <col width="41%" />
              </colgroup>
              <tbody>
                <tr>
                  <td rowSpan="4" className="title">
                    동력전달
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s021']}
                    itemKey="s021"
                    options={statusValueItems['s021']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s022', 's023', 's060'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="6" className="title">
                    조향
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s024']}
                    itemKey="s024"
                    options={statusValueItems['s024']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="5">작동상태</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s026']}
                    itemKey="s026"
                    options={statusValueItems['s026']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s025', 's061', 's062', 's027'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="3" className="title">
                    제동
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s059']}
                    itemKey="s059"
                    options={statusValueItems['s059']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s029', 's030'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="6" className="title">
                    전기
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s031']}
                    itemKey="s031"
                    options={statusValueItems['s031']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s055', 's032', 's033', 's034', 's036'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="3" className="title">
                    고전원 전기장치
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s063']}
                    itemKey={'s063'}
                    options={statusValueItems['s063']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s064', 's065'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td className="title">연료</td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s035']}
                    itemKey={'s035'}
                    options={statusValueItems['s035']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 5페이지 */}
        <div className="paper_container">
          <div className="paper">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader mini={true} />
            <div className="section_title_gray_new mt-4">
              <h5>자동차 상품화 및 소모품 상세</h5>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="18%" />
              </colgroup>
              <tbody>
                {[
                  'handle',
                  'centerfascia',
                  'seat',
                  'trunk',
                  'carExterior',
                  'carPolish',
                  'carInterior',
                  'roomCleaning',
                  'carGlass',
                ].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      className="title"
                      colSpan="1"
                      title={addTitles[item]}
                      itemKey={item}
                      options={addValueItems[item]}
                      data={addData}
                      setCheckState={setCheckState}
                    />
                    <td>{_.get(addData, `${item}Note`)}</td>
                  </tr>
                ))}
                <tr>
                  <td className="title">휠</td>
                  <td colSpan="2">
                    <div className="flex align-items-center gap-3">
                      <div className="flex align-items-center gap-1 w-6">
                        운전석 앞
                        <span className="pl-6">
                          <CheckboxGroup
                            itemKey="carWheelFL"
                            options={addValueItems['carWheelFL']}
                            data={addData}
                            setCheckState={setCheckState}
                          />
                        </span>
                      </div>
                      <div className="flex align-items-center gap-1 w-6">
                        조수석 앞
                        <span className="pl-6">
                          <CheckboxGroup
                            itemKey="carWheelFR"
                            options={addValueItems['carWheelFR']}
                            data={addData}
                            setCheckState={setCheckState}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 flex align-items-center gap-3">
                      <div className="flex align-items-center gap-1 w-6">
                        운전석 뒤
                        <span className="pl-6">
                          <CheckboxGroup
                            itemKey="carWheelRL"
                            options={addValueItems['carWheelRL']}
                            data={addData}
                            setCheckState={setCheckState}
                          />
                        </span>
                      </div>
                      <div className="flex align-items-center gap-1 w-6">
                        조수석 뒤
                        <span className="pl-6">
                          <CheckboxGroup
                            itemKey="carWheelRR"
                            options={addValueItems['carWheelRR']}
                            data={addData}
                            setCheckState={setCheckState}
                          />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="title">타이어 트레드</td>
                  <td colSpan="2">
                    <div className="flex align-items-center gap-3">
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'tiretreadFL') < 1.6 &&
                          _.get(addData, 'tiretreadFL') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        운전석 앞
                        <span className="pl-6">
                          {_.get(addData, 'tiretreadFL') === 0
                            ? '미입력'
                            : `${_.get(addData, 'tiretreadFL')} mm`}
                        </span>
                      </div>
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'tiretreadFR') < 1.6 &&
                          _.get(addData, 'tiretreadFR') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        조수석 앞
                        <span className="pl-6">
                          {_.get(addData, 'tiretreadFR') === 0
                            ? '미입력'
                            : `${_.get(addData, 'tiretreadFR')} mm`}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 flex align-items-center gap-3">
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'tiretreadRL') < 1.6 &&
                          _.get(addData, 'tiretreadRL') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        운전석 뒤
                        <span className="pl-6">
                          {_.get(addData, 'tiretreadRL') === 0
                            ? '미입력'
                            : `${_.get(addData, 'tiretreadRL')} mm`}
                        </span>
                      </div>
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'tiretreadRR') < 1.6 &&
                          _.get(addData, 'tiretreadRR') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        조수석 뒤
                        <span className="pl-6">
                          {_.get(addData, 'tiretreadRR') === 0
                            ? '미입력'
                            : `${_.get(addData, 'tiretreadRR')} mm`}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="title">브레이크 패드</td>
                  <td colSpan="2">
                    <div className="flex align-items-center gap-3">
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'brakePadFL') < 30 &&
                          _.get(addData, 'brakePadFL') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        운전석 앞
                        <span className="pl-6">
                          {_.get(addData, 'brakePadFL') === 0
                            ? '미입력'
                            : `${_.get(addData, 'brakePadFL')} %`}
                        </span>
                      </div>
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'brakePadFR') < 30 &&
                          _.get(addData, 'brakePadFR') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        조수석 앞
                        <span className="pl-6">
                          {_.get(addData, 'brakePadFR') === 0
                            ? '미입력'
                            : `${_.get(addData, 'brakePadFR')} %`}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 flex align-items-center gap-3">
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'brakePadRL') < 30 &&
                          _.get(addData, 'brakePadRL') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        운전석 뒤
                        <span className="pl-6">
                          {_.get(addData, 'brakePadRL') === 0
                            ? '미입력'
                            : `${_.get(addData, 'brakePadRL')} %`}
                        </span>
                      </div>
                      <div
                        className="flex align-items-center gap-1 w-6"
                        style={
                          _.get(addData, 'brakePadRR') < 30 &&
                          _.get(addData, 'brakePadRR') > 0
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        조수석 뒤
                        <span className="pl-6">
                          {_.get(addData, 'brakePadRR') === 0
                            ? '미입력'
                            : `${_.get(addData, 'brakePadRR')} %`}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <img
                      src={BP}
                      style={{
                        width: '100%',
                        minHeight: '70mm',
                        height: '70mm',
                        maxHeight: '70mm',
                      }}
                      className="m-0 p-0 opacity-70"
                      alt={'차량이미지'}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">특이 사항 및 점검자 의견</td>
                  <td colSpan="3" rowSpan="5">
                    {_.get(data, 'ETC_MATTER')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 6페이지 */}
        {imagePages.map((imageGroup, pageIndex) => (
          <div key={`page_${pageIndex}`} className="page-container">
            <div className="paper flex flex-column">
              <div className="watermark"></div>
              <PerformanceCheckReportHeader mini={true} />
              <div className="section_title_gray_new ">
                <h5>점검 장면 촬영 사진 (점검 부위 및 이상 부위 포함)</h5>
              </div>
              <div className="flex flex-wrap">
                {imageGroup.map((photo, idx) => {
                  if (!photo) return null;
                  const {
                    originalFileName,
                    src,
                    alt,
                    photoId,
                    partCode,
                    note,
                  } = photo;
                  return (
                    <div
                      key={`performance_check_img_${
                        originalFileName + idx + photoId
                      }`}
                      className="w-6 flex flex-column align-items-center"
                    >
                      <img
                        id={originalFileName + photoId}
                        src={`${src}?timestamp=${Date.now()}`}
                        crossOrigin="anonymous"
                        alt={alt}
                        style={{
                          width: '90%',
                          aspectRatio: '3/4',
                          objectFit: 'cover',
                          border: '1px solid lightgray',
                        }}
                      />
                      <p className="font-bold text-md p-2">
                        {PHOTO_TYPE[partCode] !== '' && note
                          ? `${PHOTO_TYPE[partCode]} - ${note}`
                          : PHOTO_TYPE[partCode] || note}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

        {/* 7페이지 */}
        <div className="paper_container">
          <div className="paper flex flex-column">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader />
            <div className="section_title_gray_new">
              <h5>개인 당사자 간 거래 성능보험 유의 사항</h5>
            </div>
            <div className="section_container flex-1 text-lg flex flex-column justify-content-center">
              <p>&lt;HD 프리미엄 개인간 당사자거래 서비스&gt;</p>
              <p>
                자동차등록원부(갑) 등·초본 상 ‘당사자 거래이전’ 차량을 대상으로
                중고자동차 성능 평가를 하고 <br />
                평가가 상이할 시 보험사 보증을 통해 “2개월, 2천키로,
                2천만원까지” 무료로 제공하여 드리는 서비스로 <br />
                세부사항은 (개인 당사자 간 거래를 위한 중고자동차 성능상태
                보증서)에 의합니다.
              </p>
              <p> 1. 매매계약 취소 및 등록말소차량 보증불가</p>
              <p>
                2. 보증 범위는 (상사거래 시) 자동차관리법 제58조의 4 및 동법
                시행규칙 별지 제82호에 의한
                <br />
                ‘중고자동차 성능상태점검(84가지)’ 범위와 동일합니다.
              </p>
              <p>
                3. 위 검사 방법으로 점검불가 또는 점검하지 않는 부분(예.
                소모품)은 보증 제외됩니다.
              </p>
              <p>
                4. 보험사 신청 시에는 HD 프리미엄 중고차 성능상태기록부와
                등록증을 반드시 제출 신청하셔야 합니다.
              </p>
            </div>
            <div className="certificate">
              <p className="title">
                HD 프리미엄 개인 당사자 간 거래를 위한 성능 점검지를 발급합니다.
              </p>
              <div className="date-section">
                <div className="date">
                  <p>{formatDate(data?.CHCK_DE)}</p>
                  <p className="subtext">
                    (유효기간 : {getEndDate(data?.CHCK_DE)}까지) - 발급일로부터
                    2개월
                  </p>
                </div>
              </div>

              <div className="flex justify-content-around mt-5">
                <p>개인 직거래 상태 점검자</p>

                <div>
                  <div className="flex justify-content-between mb-7 relative">
                    <span>한독자동차㈜</span>
                    <div style={{ minWidth: '50px' }}>
                      {officialSealUrls.association && (
                        <img
                          src={_.get(officialSealUrls, 'association')}
                          alt="association_officialSeal"
                          style={{
                            position: 'absolute',
                            width: '50px',
                            top: '-10px',
                            right: '-5px',
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex-1 flex justify-content-between gap-2 relative">
                    <span>점검자 : </span>
                    <span>
                      {_.get(data, 'CHCK_ENTRPS_NM').split('/').pop().trim()}
                    </span>
                    <span>{_.get(data, 'INSCTR_NM')}</span>
                    <div style={{ minWidth: '50px' }}>
                      {officialSealUrls.inspector && (
                        <img
                          src={_.get(officialSealUrls, 'inspector')}
                          alt="association_officialSeal"
                          style={{
                            position: 'absolute',
                            width: '50px',
                            top: '-10px',
                            right: '-5px',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 8페이지 */}
        {_.get(data, 'ICNY_CODE') === '09' && (
          <div className="paper_container">
            <div className="paper">
              <div className="watermark"></div>
              <div className="hyundai">
                <h1>중고자동차 성능상태 보증서</h1>
                {/* <Image src={HyundaiHeader} width="100%" alt="logo" /> */}
                <h2>중고차 직거래 성능보증 서비스란?</h2>
                <p>
                  중고차 직거래 보증 서비스는 한독성능장에서 개인간 직거래를
                  위하여 당사의 점검기준(약관)에 의하여 받은 성능상태점검 경과
                  하자로 인하여 점검자가 부담하는 수리손해 위험을 보장하고자
                  한독성능장이 현대해상화재보험에 보험을 가입하여 이용고객에게
                  무료로 제공하는 보증서비스입니다.
                </p>

                <h2>계약기본사항</h2>
                <table className="font-bold">
                  <tbody>
                    <tr>
                      <td className="text-center">종목</td>
                      <td>자동차성능상태점검 보증</td>
                      <td className="text-center">증권 NO.</td>
                      <td>F-2024-0183145</td>
                    </tr>
                    <tr>
                      <td className="text-center">주행거리</td>
                      <td>
                        {CommonUtil.Unit.format(_.get(data, 'TRVL_DSTNC'))}
                        km
                      </td>
                      <td className="text-center">점검자</td>
                      <td>한독자동차㈜ 한독성능장</td>
                    </tr>
                    <tr>
                      <td className="text-center">차량번호</td>
                      <td>{_.get(data, 'VHRNO')}</td>
                      <td className="text-center">차대번호</td>
                      <td>{_.get(data, 'VIN')}</td>
                    </tr>
                  </tbody>
                </table>

                <h2>보장내용 및 보장기간</h2>
                <p>중고차 품질평가일 기준 60일 또는 주행거리 2,000km 이내</p>
                <ol>
                  <li>
                    보증기간은 중고차 품질평가일을 시작일로 하여 기간과 주행거리
                    중 먼저 도래한 것을 보증기간의 만료로 간주합니다.
                  </li>
                  <li>
                    본 서비스 보증기간 60일 이내에 보장 차량이 직거래 되지 않은
                    경우 자동 종료되며, 보증기간 내 이전 등록된 경우라도 해당
                    등록일을 포함하여 60일을 초과할 수 없습니다.
                  </li>
                </ol>
                <p>
                  보장 차량의 품질점검 상태가 상이하여 해당 부품의 기능이상으로
                  차량정비업체에서 수리한 경우 다음과 같이 보장해 드립니다.
                  <br />
                  (자동차 검사 방법으로 점검이 불가한 경우는 원칙적으로 보증에서
                  제외됩니다)
                </p>
                <ol>
                  <li>국산차/외제차 1회 입고한 2천만원</li>
                  <li>
                    보장 차량의 품질평가와 보장차량의 외판부위, 주요골격 상태가
                    상이한 경우 아래와 같이 보장합니다.
                  </li>
                  <table>
                    <colgroup>
                      <col width="13%" />
                      <col width="67%" />
                      <col width="10%" />
                      <col width="10%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colSpan="2">구분</th>
                        <th>국산차</th>
                        <th>외산차</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan="2">외판부위</td>
                        <td>
                          후드, 프론트휀더, 도어, 트렁크리드, 라디에이터서포트,
                          쿼터패널, 사이드실패널
                        </td>
                        <td className="text-center">30만원</td>
                        <td className="text-center">50만원</td>
                      </tr>
                      <tr>
                        <td className="text-left">루프패널</td>
                        <td className="text-center">40만원</td>
                        <td className="text-center">70만원</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">주요골격</td>
                        <td>
                          프론트패널, 크로스멤버, 인사이드패널, 사이드멤버,
                          필러패널, 대쉬패널 트렁크플로어, 리어패널
                        </td>
                        <td className="text-center">30만원</td>
                        <td className="text-center">50만원</td>
                      </tr>
                      <tr>
                        <td className="text-left">휠하우스, 플로어패널</td>
                        <td className="text-center">40만원</td>
                        <td className="text-center">70만원</td>
                      </tr>
                    </tbody>
                  </table>
                  <li>
                    A/S보증한도를 초과한 수리 비용에 대하여는 고객이 부담하여야
                    합니다.
                  </li>
                </ol>

                <h2>고객자기부담금</h2>
                <p>
                  ① 1회 수리 입고당 - 10만원&nbsp;&nbsp; ② 외판부위, 주요 골격 -
                  국산차/외제차 10만원
                </p>

                <h2>보증제외 차량</h2>
                <p>
                  주행거리 13만km 이상의 차량, 1톤 초과 화물차량, 버스 & 택시,
                  렌터카 및 리스 차량 등 영업 차량, 레이싱카, 퍼포먼스 차량,
                  시험테스트 차량, 특수 차량 및 이륜/삼륜 차량, 공공기관용 차량,
                  제조 12년(출고일 포함) 경과 차량은 서비스 대상에서 제외
                  됩니다.
                </p>

                <h2>
                  보상절차 안내 [상담 및 접수센터 :
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ]
                </h2>
                <p>
                  보험사고 발생 → 보험사고 접수 → 지정정비소 입고 → 보상여부
                  판단 → 처리 완료 및 차량출고
                </p>
                <h5>
                  직거래 성능보증에 대한 보증범위, 보증수리, 보상제외 등에 대한
                  세부 내용은 직거래 서비스 약관에 의합니다.
                </h5>
                <div className="w-full flex justify-content-end pb-5">
                  <div className="w-3 flex justify-content-between font-bold relative">
                    <span>한독자동차㈜</span>
                    <div style={{ minWidth: '50px' }}>
                      {officialSealUrls.association && (
                        <img
                          src={_.get(officialSealUrls, 'association')}
                          alt="association_officialSeal"
                          style={{
                            position: 'absolute',
                            width: '40px',
                            top: '-13px',
                            right: '20px',
                          }}
                        />
                      )}
                    </div>
                    <span>(인)</span>
                  </div>
                </div>
                <Image src={HyundaiFooter} width="100%" alt="logo" />
              </div>
            </div>
          </div>
        )}

        {payment &&
          _.get(isAvailable, 'certificate') &&
          renderInsuranceCertificate(_.get(payment, 'ICNY_CODE'))}

        {payment &&
          _.get(isAvailable, 'ew') &&
          renderEwInsuranceCertificate(_.get(payment, 'ICNY_CODE'))}
      </div>
    );
  }
);

export default PerformanceCheckReport;
